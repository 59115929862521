import {InjectionToken} from '@angular/core'
import {environment} from '../../environments/environment'

export interface IEnvironment {
  // Start with this, add more if needed.
  production: boolean
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')

export const environmentProvider = () => {
  return environment
}

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const WINDOW = new InjectionToken<Window>('window')

export const windowProvider = () => {
  return window
}